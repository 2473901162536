import axios from "axios";
import constants from '@/utils/constants.js';
import router from '@/routes';
import { jwtDecode } from "jwt-decode";

export const TokenRole = {
    NoAuth: 'NoAuth',
    User: 'User',
    Admin: 'Admin',
};

var instance = axios.create({
    baseURL: constants.TOKEN_SERVICE_URL,
});


const JWT_KEY = "_JWT_TOKEN";

export function getJWTData() {
    const jwt = localStorage.getItem(JWT_KEY);

    if (!(jwt && jwt.length)) throw new Error("No valid JWT Token.");
    

    const data = jwtDecode(jwt);
    if (!(data?.exp && data.exp * 1000 >= Date.now())) throw new Error("JWT Token expired.");

    if (data.role_name !== 'Admin') data.role_name = TokenRole.User;

    return data;
}

export async function getTokenData() {
    const token = router.currentRoute.query.token;
    let tryVerify = false;
    
    if (token) tryVerify = true;

    if (!tryVerify) {
        try {
            return getJWTData();
        } catch (e) {
            if (e.message === "No valid JWT Token." || e.message === "JWT Token expired.") tryVerify = true;
        }
    }

    if (tryVerify) {
        const token = router.currentRoute.query.token;
        if (!token) return null;
    
        return await instance.post(
            '',
            {
                token,
            },
        )
            .then(response => {
                setJWT(response.data.token);
                return getJWTData();
            })
            .catch(error => {
                return null;
            });
    }

    return null;
}

export function setJWT(jwt) {
    localStorage.setItem(JWT_KEY, jwt);
}

export async function getRole() {
    const defaultValue = TokenRole.NoAuth;

    let data = await getTokenData();
    if (!data) return defaultValue;

    return data.role_name;
}

export async function isTokenValid() {
    return (await getRole()) !== TokenRole.NoAuth;
}

export async function isViewable() {
    return (await getRole()) === TokenRole.Admin;
}

const Token = {
    TokenRole,
    getTokenData,
    getRole,
    isTokenValid,
    isViewable,
}

export default Token;
