import axios from "axios";

var instance = axios.create({
    validateStatus: function (status) {
        return status == 200;
    }
});

export async function getPendingFiles() {
    return await instance.get(`/files/pending`)
        .then(response => {
            return response.data;
        });
}

export async function importImage(filepath) {
    return await instance.post(`/import/${filepath}`)
        .then(response => {
            return response.data;
        });
}

export async function deleteImage(id) {
    return await instance.delete(`/images/${id}`)
        .then(response => {
            return response.data;
        });
}

const ViewerClient = {
    getPendingFiles,
    importImage,
    deleteImage,
}

export default ViewerClient;
