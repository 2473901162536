var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cytomine-modal',{attrs:{"active":_vm.active,"title":_vm.$t('add-images')},on:{"close":function($event){_vm.activeInternal = false}}},[_c('b-loading',{staticClass:"small",attrs:{"is-full-page":false,"active":_vm.loading}}),(!_vm.loading)?[[(_vm.pendingFiles.length === 0)?_c('b-table',{attrs:{"data":_vm.pendingFiles},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_vm._v(_vm._s(_vm.$t('no-image')))])])]},proxy:true}],null,false,3371546040)}):_c('zk-table',{attrs:{"data":_vm.pendingFiles,"columns":[
          {
            label: _vm.$t('name'),
            prop: 'name',
          },
          {
            label: _vm.$t('status'),
            prop: 'uploaded',
            type: 'template',
            template: 'status',
          },
          {
            label: _vm.$t('start-upload'),
            prop: 'filepath',
            type: 'template',
            template: 'actions',
          },
        ],"is-fold":false,"expand-type":false,"selection-type":false},scopedSlots:_vm._u([{key:"status",fn:function(scope){return [(scope.row.uploaded)?_c('span',{staticClass:"tag",class:scope.row.uploaded ? 'is-success' : ''},[_vm._v(" "+_vm._s(_vm.$t('uploaded'))+" ")]):_vm._e()]}},{key:"actions",fn:function(scope){return [(scope.row.filepath !== '')?_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button is-small is-link",on:{"click":function($event){return _vm.addImage(scope.row.filepath)}}},[_vm._v(" "+_vm._s(_vm.$t('button-add'))+" ")])]):_vm._e()]}}],null,false,3001879083)})]]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }