<template>
  <div v-if="isViewable">
    <slot></slot>
  </div>
</template>

<script>
import Token from '@/utils/token';

export default {
  name: 'token-viewable-outlet',
  data: () => {
    return {
      isViewable: false,
    }
  },
  mounted() {
    Token.isViewable().then(isViewable => this.isViewable = isViewable);
  }
};
</script>
