<template>
  <div>
    <slot v-if="roleInternal === role"></slot>
  </div>
</template>

<script>
import Token from '@/utils/token';

export default {
  name: 'token-outlet',
  props: {
    role: String,
  },
  data: () => {
    return {
      roleInternal: Token.TokenRole.NoAuth,
    }
  },
  mounted() {
    Token.getRole().then(role => this.roleInternal = role);
  }
};
</script>
